<template>
  <header class="header" :class="darkMode ? 'dark' : ''">
    <div class="burger-and-title">
      <svg class="burger" @click="$emit('burgerClick')">
        <use xlink:href="#icon-menu" />
      </svg>
      <div class="header-title">
        <h1 v-if="!Array.isArray(title)">{{ title }}</h1>
        <h1 v-else-if="title.length === 1">{{ title[0].name }}</h1>
        <UISelect v-else-if="$route" v-model="titleSelectForMonitor">
          <option
          v-for="option in title"
          :key="option.locationId"
          :value="option.locationId"
          :id="option.name"
          >{{ option.name }}</option>
        </UISelect>
      </div>
    </div>

    <div v-if="user.id" class="notification-and-user">
      <div class="user">
        <div class="avatar-and-name" @click="openUserMenu">
          <div class="name-status">
            <span class="name">{{ getUserDisplayName }}</span>
            <span class="status" v-if="$store.getters.isTenant">{{ $t('Global Admin Mode') }}</span>
          </div>
          <svg>
            <use xlink:href="#icon-chevron-down" />
          </svg>
        </div>

        <div class="user-menu" :class="{'open': userMenuOpen}">
          <ul slot="user-menu">
            <RouterLink
              tag="li"
              v-if="showUserSettings"
              :to="{ name: 'user-settings' }"
              @click.native="closeUserMenu"
            >{{ $t('My Profile') }}</RouterLink>
            <li v-for="option in userOptions" :key="option.name" @click="closeUserMenu">
              <RouterLink
                class="click-area"
                v-if="option.routerLink"
                tag="div"
                :to="option.routerLink"
              >{{ option.name }}</RouterLink>
              <div class="click-area" v-if="option.action" @click="option.action">{{ option.name }}</div>
              <a
                v-if="option.externalLink"
                :href="option.externalLink"
                rel="noopener"
              >{{ option.name }}</a>
            </li>
            <li v-if="$store.getters.isTenant">
              <a :href="tenantLogout" rel="noopener">{{ $t('Back To Global Admin') }}</a>
            </li>
            <li v-if="showSupport">
              <a
                href="https://support.springboardvr.com/"
                target="_blank"
                rel="noopener"
              >{{ $t('Support') }}</a>
            </li>
            <li
              v-if="showLogout && !$store.getters.isTenant"
              @click="$store.dispatch('logout')"
            >{{ $t('Logout') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import handleClickOutside from '../helpers/handleClickOutside.js';
import UISelect from './ui/UISelect';

@Component({
  components: {
    UISelect,
  },
  props: {
    title: {
      type: [String, Array],
      validator (value) {
        if (Array.isArray(value)) {
          return value.every(option => {
            return 'name' in option &&
              'locationId' in option &&
              typeof option.name === 'string' &&
              typeof option.locationId === 'string';
          });
        }
        return true;
      },
    },
    showBurger: {
      type: Boolean,
      default: true,
    },
    showNotification: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {
        return {
          avatarUrl: 'https://api.springboardvr.com/assets/img/logos/springboardvr-icon-onwhite.png',
          name: 'Jamie',
        };
      },
    },
    userOptions: {
      type: Array,
    },
    showLogout: {
      type: Boolean,
      default: true,
    },
    showSupport: {
      type: Boolean,
      default: true,
    },
    showUserSettings: {
      type: Boolean,
      default: true,
    },
    showOrganizationSettings: {
      type: Boolean,
      default: true,
    },
    darkMode: {
      type: Boolean,
      required: false,
    },
  },
})
export default class SBVRHeader extends Vue {
  userMenuOpen = false;
  notificationMenuOpen = false;

  get tenantLogout () {
    return `//${window.sbvrenv.PANEL_URL}/nova`;
  }

  get titleSelectForMonitor () {
    return this.$route.params.locationId;
  }

  set titleSelectForMonitor (locationId) {
    this.$emit('selectedLocationId', locationId);
    this.$router.push({ name: 'overview', params: { locationId } });
  }

  get getUserDisplayName () {
    if (/[A-Z]/.test(this.user?.name)) {
      return this.user?.name; // Let user have his own spelling
    }
    const arr = this.user?.name?.split(' ');
    if (arr !== undefined) {
      for (let x = 0; x < arr.length; x++) {
        arr[x] = (arr[x][0].toUpperCase() + arr[x].substring(1));
      }
    }
    return arr.join(' ');
  }

  openUserMenu () {
    handleClickOutside('.header .user-menu', this.userMenuOpen, this.closeUserMenu);
    this.userMenuOpen = true;
  }

  closeUserMenu () {
    this.userMenuOpen = false;
  }

  openNotificationMenu () {
    handleClickOutside('.header .notification-menu', this.notificationMenuOpen, this.closeNotificationMenu);
    this.notificationMenuOpen = true;
  }

  closeNotificationMenu () {
    this.notificationMenuOpen = false;
  }
}
</script>

<style scoped lang="postcss">
@import "../styles";

.header {
  @apply --container;

  display: flex;
  justify-content: space-between;
  z-index: var(--zHeader);
  background-color: var(--colorWhite);
  padding: var(--spacingSm);
  max-height: auto;
  box-shadow: 0 0.3rem 3.6rem 0 color-mod(var(--colorManatee) alpha(16%));

  @media (--tablet) {
    padding: var(--spacingSm) var(--spacingMd);
  }

  &.header.dark {
    background-color: var(--darkModeColorDarkBackground);
    color: var(--darkModeColorText);
  }

  &.header.dark .notification-and-user .user .user-menu ul li {
    background-color: var(--darkModeColorDarkBackground);
    color: var(--darkModeColorText) !important;
  }

  &.header.dark .notification-and-user .user .user-menu ul li a {
    background-color: var(--darkModeColorDarkBackground);
    color: var(--darkModeColorText) !important;
  }

  &.header.dark .notification-and-user {
    fill: var(--darkModeColorText);
  }

  &.header.dark .burger-and-title .header-title div /deep/ .ui-select {
    background-color: var(--darkModeColorDarkBackground);
    color: var(--darkModeColorText) !important;
  }

  & .burger-and-title {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1 1 auto;

    & .burger {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      margin-right: var(--spacingSm);
      cursor: pointer;
      flex: 0 0 2rem;
    }

    & .header-title {
      & h1 {
        @apply --f2;

        margin: 0;
        text-align: left;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.4;
        width: calc(100vw - 17rem);

        @media (--tablet) {
          width: auto;
        }
      }

      & >>> select {
        padding-top: var(--spacingXS);
        padding-bottom: var(--spacingXS);
      }
    }
  }

  & .notification-and-user {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    max-width: 11rem;

    @media (--tablet) {
      max-width: 100%;
    }

    & .notification {
      position: relative;
      margin-right: var(--spacingSm);

      & .notification-icon {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.2rem solid var(--colorLightGrey);
        border-radius: 50%;
        cursor: pointer;

        & svg {
          stroke: var(--colorManatee);
          width: 1rem;
          height: 1rem;
        }
      }

      @media (--tablet) {
        margin-right: var(--spacingMd);

        & .notification-item {
          width: 3rem;
          height: 3rem;

          & svg {
            width: 1.75rem;
            height: 1.75rem;
          }
        }
      }
    }

    & .user {
      position: relative;

      & .avatar-and-name {
        display: flex;
        align-items: center;
        cursor: pointer;

        & .name {
          @apply --f4;

          display: block;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 8rem;

          @media (--tablet) {
            max-width: 100%;
          }
        }

        & .status {
          @apply --f6;

          display: none;

          @media (--tablet) {
            display: block;
          }
        }

        & svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-left: var(--spacingSm);
        }
      }

      & .user-menu {
        @apply --panelDropShadow;

        position: absolute;
        top: calc(100% + 1.7rem);
        right: calc(-1 * var(--spacingSm));
        width: 100vw;
        min-height: 100vh;
        background-color: var(--colorWhite);
        transform-origin: top center;
        transform: scaleY(0) translateZ(0);
        transition: transform 0.15s ease 0.15s, opacity 0.15s ease 0.15s;
        opacity: 0;
        will-change: transform, opacity;
        z-index: -1;

        @media (--tablet) {
          right: 0;
          max-width: 20rem;
          min-height: auto;
        }

        & ul {
          padding-left: 0;
          list-style-type: none;
          margin: 0;
          overflow: hidden;

          & li {
            @apply --f5;

            margin: 0;
            padding: var(--spacingSm);
            background-color: var(--colorWhite);
            cursor: pointer;
            transform: translateZ(0);
            opacity: 0;
            will-change: opacity;
            transition: opacity 0.15s ease;
            position: relative;
            min-height: 5rem;

            & a {
              text-decoration: none;
              color: var(--colorSlate);
              display: block;
              position: absolute;
              padding: var(--spacingSm);
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            &:hover {
              background-color: var(--colorCasper);
            }
          }
        }

        &.open {
          transform: scale(1) translateZ(0);
          transition: transform 0.15s ease, opacity 0.15s ease;
          opacity: 1;

          & li {
            opacity: 1;
            transform: translateZ(0);
            transition: opacity 0.3s ease 0.15s;
          }
        }
      }
    }
  }
}
</style>
