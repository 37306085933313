<template>
  <div
    id="app"
    :class="{ 'show-nav-menu': !navMenu.hide && !hideNavMenu, 'show-header': showHeader, 'no-user': !isThereAUser, 'nav-menu-collapsed': navMenu.collapsed, 'dark': darkMode }"
  >
    <slot></slot>
    <template v-if="globalMessages">
      <GlobalMessage
        v-for="({ background, message, buttonText, action, html }, i) in commonGlobalMessages"
        :key="i"
        :button-text="buttonText"
        :message="message"
        :background="background"
        :readonly="!action"
        :html="html"
        @click="globalMessageAction(action)"
      />
    </template>
    <icons />
    <div class="flexcontainer">
      <nav-menu
      class="navmenu"
      v-if="isThereAUser && !navMenu.hide && !hideNavMenu"
      @closeNavMenu="closeNavMenu"
      @setCollapsed="setNavMenuCollapsed"
      :open="navMenu.open"
      :collapsed="navMenu.collapsed"
      :navigation="navigation"
      :applications="applications"
      :topLink="topLink"
      />
      <div class="maincontent" :class="{'-header': showHeader && isThereAUser}">
        <sbvr-header
          v-if="showHeader && isThereAUser"
          @burgerClick="burgerClick"
          @selectedLocationId="selectedLocationId"
          :user="user"
          :userOptions="userOptions"
          :title="title"
          :darkMode="darkMode"
          :showUserSettings="showUserSettings"
          :showLogout="showLogout"
        />
        <router-view v-if="isInititialized" />
      </div>
    </div>
    <div class="overlay" v-if="showLoadingOverlay">
      <Loading />
    </div>
    <confirm v-bind="confirm" @closeConfirm="closeConfirm" />
    <flash v-if="flash" v-bind="flash" :key="flash.id" />

    <loading-bar :loading="loading" />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import LoadingBar from '../components/LoadingBar';
import Loading from '../components/Loading';
import Header from '../components/Header';
import Flash from '../components/Flash';
import Confirm from '../components/Confirm';
import NavMenu from '../components/NavMenu';
import Icons from '../components/Icons';
import NetworkService from '../services/NetworkService';
import GlobalMessage from '../components/GlobalMessage';
import EventBus from '../services/EventBus';

@Component({
  computed: mapGetters([
    'confirm',
    'flash',
    'loading',
    'navMenu',
    'showLoadingOverlay',
    'title',
    'showHeader',
  ]),
  components: {
    NavMenu,
    'sbvr-header': Header,
    LoadingBar,
    Loading,
    GlobalMessage,
    Icons,
    Flash,
    Confirm,
  },
  watch: {
    $route () {
      this.closeNavMenu();
    },
  },
  data () {
    return {
      darkMode: false,
    };
  },
})
export default class WebPanelMixin extends Vue {
  topLink = false;
  isInititialized = false;
  usesAuth = true;
  hideNavMenu = false;
  showLogout = true;

  get user () {
    return this.$store.getters.currentUser;
  }

  get isThereAUser () {
    if (this.user) {
      return !!this.user.id &&
        !!this.user.agreedAt &&
        (!!this.user.upcomingAgreedAt || !this.user.upcomingAgreedAtRequiredBy);
    }
  }

  get showUserSettings () {
    return true;
  }

  get globalMessages () {
    return [];
  }

  get commonGlobalMessages () {
    const swUpdateMsg = this.$t(
      'There is a new version of the {panel} available!',
      { panel: this.$t(this.$store.getters.projectName) },
    );
    const swUpdateButtonText = this.$t('Update & Reload');
    const firstFailPaymentErrorMsg = this.$t(
      'Your last payment failed. Please update your credit card information within 48 hours.',
    );
    const secondFailPaymentErrorMsg = this.$t(
      'Your last payment failed. Please fix this issue within 48 hours or your account will be suspended.',
    );
    const suspendedAccountMsg = this.$t(
      'Your account has been suspended due to payment failure.',
    );
    const expiredErrorMsg = this.$t(
      'Your credit card has expired, please update your payment information.',
    );
    const goToAccountButtonText = this.$t('Go to My Account');
    const messages = [];
    if (this.$store.getters.swUpdateAvailable) {
      messages.push({
        message: swUpdateMsg,
        buttonText: swUpdateButtonText,
        action: ['updateServiceWorker'],
        background: 'orange-flat',
      });
    }
    if (
      this.$store.getters.currentOrganization &&
      this.$store.getters.currentOrganization.paymentError
    ) {
      if (this.$store.getters.currentOrganization.paymentError.suspended) {
        messages.push({
          message: suspendedAccountMsg,
        });
      } else if (
        this.$store.getters.currentOrganization.paymentError.failedCount === 1
      ) {
        messages.push({
          message: firstFailPaymentErrorMsg,
          buttonText: goToAccountButtonText,
          action: ['goToMyBillingAccount'],
        });
      } else if (
        this.$store.getters.currentOrganization.paymentError.failedCount === 2
      ) {
        messages.push({
          message: secondFailPaymentErrorMsg,
          buttonText: goToAccountButtonText,
          action: ['goToMyBillingAccount'],
        });
      }
    } else if (
      this.$store.getters.currentOrganization &&
      this.$store.getters.currentOrganization.creditCardExpired
    ) {
      messages.push({
        message: expiredErrorMsg,
        buttonText: goToAccountButtonText,
        action: ['goToMyBillingAccount'],
      });
    }
    return [...messages, ...this.globalMessages];
  }

  globalMessageAction (action) {
    this.$store.dispatch(...action);
  }

  get promptPadding () {
    return `${5}rem`;
  }

  get navigation () {
    return [];
  }

  get applications () {
    return [];
  }

  get userOptions () {
    return [];
  }

  beforeCreate () {
    // we are using an event bus for routing inside vuex-actions because we need the store instance for routing-logic (permissions, etc...)
    // but we have to avoid circular references
    EventBus.$on('router:push', (...payload) => {
      this.$router.push(...payload);
    });
    EventBus.$on('router:replace', (...payload) => {
      this.$router.replace(...payload);
    });
  }

  created () {
    this.init();
  }

  async init () {
    this.initNetworkService();
    if (this.usesAuth) await this.$store.dispatch('getUserFromToken');
    // this.$store.commit('setNavMenuOpen', true);
    this.$store.commit('setNavMenuHide', !!this.hideNavMenu);
    this.isInititialized = true;
  }

  initNetworkService () {
    NetworkService.init({
      notify: message =>
        this.$store.commit('setFlash', { message, type: 'info', display: 'flash' }),
      onlineMessage: this.$t('Back online.'),
      offlineMessage: this.$t('Network changed, you are now offline.'),
    });
  }

  closeConfirm () {
    this.$store.commit('setConfirm', {
      message: '',
      buttons: [],
      show: false,
      onClose: null,
    });
  }

  burgerClick () {
    this.$store.commit('setNavMenuOpen', true);
  }

  closeNavMenu () {
    this.$store.commit('setNavMenuOpen', false);
  }

  setNavMenuCollapsed (value) {
    this.$store.commit('setNavMenuCollapsed', value);
  }

  setDarkModeBody (darkMode) {
    if (darkMode) {
      document.body.style.backgroundColor = '#182942';
      document.documentElement.style.backgroundColor = '#182942';
    } else {
      document.body.style.backgroundColor = '#F7F8F8F8';
      document.documentElement.style.backgroundColor = '#F7F8F8F8';
    }
  }

  selectedLocationId (locationID) {
    const result = this.$store.getters.entities.Location[locationID];
    this.darkMode = result.darkMode;
    this.setDarkModeBody(this.darkMode);
  }

  async mounted () {
    this.$watch(
      () => this.$store.getters.entities.Location,
      (newLocations) => {
        if (newLocations && Object.keys(newLocations).length > 0) {
          const currentLocation = newLocations[this.$route.params.locationId];
          this.darkMode = currentLocation.darkMode;
          this.setDarkModeBody(this.darkMode);
        }
      },
      { immediate: true },
    );
  }
}
</script>

<style lang="postcss">
@import "../styles/static/index.css";

body.-no-scroll {
  overflow: hidden;
}
#app {
  width: 100%;
  &.show-header {
    /* padding-top: 5.25rem; */
  }

  & > .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
  }
  & .burger {
    display: none;
  }
  & .flexcontainer {
    position: relative;
    display: flex;
    width: 100%;

    & > .maincontent {
      flex: 1 1 auto;
      width: 100%;
      min-width: 0;
      padding-bottom: 6rem;
      &.-header {
        padding-top: 6rem;
      }
    }
  }

  &.show-nav-menu {
    & .burger {
      display: flex;
    }
  }

  & .loading-bar {
    position: fixed;
    top: 0;
    left: 0;
  }

  & .header {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  & .navmenu {
    width: 0;
    flex-shrink: 0;
  }
  @media (--desktop) {
    & .navmenu {
      width: 30rem;
    }
    &.show-nav-menu {
      /* will-change: padding-left; */
      /* transition: padding-left 0.3s ease; */
      /* padding-left: 30rem; */

      /* &.nav-menu-collapsed {
        padding-left: 5rem;
      } */
    }

    &.show-header {
      /* padding-top: 5.75rem; */

      & .header {
        & .burger {
          display: none;
        }
      }

      & .nav-menu {
        transform: translateX(0);
      }
    }

    &.show-nav-menu.show-header {
      & .header {
        width: calc(100% - 30rem);
        will-change: width;
        transform: translateZ(0);
        transition: width 0.3s ease;
      }

      &.nav-menu-collapsed {
        & .header {
          width: calc(100% - 5rem);
        }
      }
    }
  }

  &.no-user {
    padding: 0;
  }
}

</style>
